<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(saveModel)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ modalTitle(name) }} <strong>Modelo</strong></span>
					<span v-if="model.id">#{{ model.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<InputWithValidation class="mb-5" rules="required|min:1" type="text" label="PNO12" size="is-medium" v-model="model.pno" />

					<InputWithValidation class="mb-5" rules="required|min:1" type="text" label="Vehículo" size="is-medium" v-model="model.vehicle" />

					<InputWithValidation class="mb-5" rules="required|min:1" type="text" label="Versión" size="is-medium" v-model="model.version" />

					<InputWithValidation class="mb-5" rules="required|min:3" type="text" label="Precio" size="is-medium" v-money="money2" v-model="model.base_price" />

					<InputWithValidation class="mb-5" rules="required|min:3" type="text" label="Porcentaje" size="is-medium" v-money="money" v-model="model.percent" />

					<small class="modal-updated" v-if="model.updated_at">Última Actualización: {{ format(model.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Cerrar</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">Guardar</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import { VMoney } from 'v-money'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'

export default {
	components: {
		InputWithValidation,
		ValidationObserver
	},
	directives: {
		money: VMoney
	},
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			model: {
				pno: '',
				vehicle: '',
				version: '',
				base_price: 0,
				percent: 0
			},
			models: [],
			money: {
				decimal: '.',
				thousands: '',
				prefix: '',
				precision: 2
			},
			money2: {
				decimal: ',',
				thousands: '.',
				prefix: '$ ',
				precision: 1
			}
		}
	},
	methods: {
		async saveModel() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post(`model/store`, this.model)
				const { status } = response
				if (status === 201) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/models')
					successToast('¡El modelo fue <strong>registrado</strong> exitosamente!')
					eventHub.$emit('reload-models')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('¡Ocurrió un <strong>error</strong> al crear el modelo!')
				}
			} finally {
				this.loading = false
			}
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`model/update/${this.id}`, this.model)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/models')
					successToast('¡El modelo fue <strong>actualizado</strong> exitosamente!')
					eventHub.$emit('reload-models')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('¡Ocurrió un <strong>error</strong> al actualizar el modelo!')
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`model/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.model = { ...data, base_price: parseFloat(data.base_price).toFixed(1) }
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
